import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollTop from './components/ScrollTop';
import Home from './views/Home';
import ChiSiamo from './views/ChiSiamo';
import Blog from './views/Blog';
import Progetti from './views/Progetti';
import Contattaci from './views/Contattaci';
import LayoutArticolo from './views/LayoutArticolo';
import CookiePolicy from './views/CookiePolicy';
import PageNotFound from './views/PageNotFound';

export default function App() {
	const location = useLocation();
	useEffect(() => {
		!location.hash ?
			setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0) :
			localStorage.removeItem('position');
	}, [location]);

	return (
		<>
			<Header />

			<main>
				<Routes location={location} key={location.pathname}>
					<Route path='/'>
						<Route index element={<Home />} />
						<Route path='chi-siamo' element={<ChiSiamo />} />
						<Route path='blog'>
							<Route index element={<Blog />} />
							<Route path=":newsName" element={<LayoutArticolo />} />
						</Route>
						<Route path='progetti' element={<Progetti />} />
						<Route path='contattaci' element={<Contattaci />} />
						<Route path='cookie-policy' element={<CookiePolicy />} />
						<Route path='/*' element={<PageNotFound />} />
					</Route>
				</Routes>
			</main>

			<ScrollTop />
			<Footer />
		</>
	);
}
