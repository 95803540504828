import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import newsList from '../data/news';
import projectsList from '../data/projects';
import ArticlePreview from '../components/ArticlePreview';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionContact from '../components/SectionContact';
import mission from '../imgs/mission.webp';


export default function Home() {
	return (
		<>
			<Helmet>
				<title>Travolgi &bull; Siti Web per distinguerti!</title>
				<meta name="description" content="Sviluppiamo il tuo sito web su misura: al primo posto le tue necessità e i tuoi obiettivi. Cosa c'è di meglio? Non perderai tempo perché faremo tutto noi!" />
				<meta name="keywords" content="travolgi, sito web, sito wordpress, sito web aziendale, sito web responsive, responsive, responsive design, sito personalizzato, sito professionale,  sviluppo siti web, comunicazione, perché avere sito web, sito web a norma di legge, sito web per palestra, web design, web designer, web agency, agenzia web, digital agency, web agency vicenza, web agency venezia, web agency verona, web agency treviso, web agency padova, agenzia web marketing, sviluppatore web, front end developer, marketing online, branding, mission, valori aziendali, bassano del grappa" />
			</Helmet>

			<section id="slider">
				<div className="container">
					<h1>Fatti conoscere online e raggiungi <span className="txt-gradient">nuovi clienti</span></h1>
					<h2 className="w-60 fw-400">Aiutiamo aziende e imprenditori ad avere il sito web che hanno sempre desiderato per farsi notare e conoscere in meno di 29 giorni</h2>

					<HashLink to="#contattaci" className="btn">Contattaci ora</HashLink>

					<div className="flex space-between g-2">
						<h2><span className="pictogram" /> Design esclusivo</h2>
						<h2><span className="pictogram" /> Dinamicità e carattere</h2>
						<h2><span className="pictogram" /> Semplicità</h2>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="mission" />
				<div className="container grid g-2">
					<div className="fingerprint flex">
						<img src={mission} alt="La nostra Mission • Travolgi" />
					</div>

					<article>
						<h1>La nostra <span className="txt-gradient">Mission</span></h1>
						<h2>
							<em>Internet è un oceano di ordinarietà: riuscirebbe un tuo cliente a identificarti subito a km di distanza?</em>
						</h2>
						<p>Immagina di avere il tuo personal stylist sempre al tuo fianco, ma questa volta per il tuo <strong>business online</strong>! Il nostro obiettivo è far diventare il tuo sito web un VIP del mondo digitale!
						<br />La nostra missione: far emergere la tua <strong>personalità</strong> e creare un sito web che sia unico quanto te, facile da usare ma anche funzionale come un orologio svizzero!</p>
						<p className="mb-3">Con il nostro approccio <strong>personalizzato</strong>, non solo ti assicuriamo un design unico e funzionale che ti farà risaltare tra la folla, ma anche un'esperienza di sviluppo divertente e senza stress!</p>

						<HashLink to="#contattaci" className="btn">RACCONTACI DI TE</HashLink>
					</article>
				</div>
			</section>
			
			<span className="sr-only offset-nav" id="vantaggi" />
			<section className="accent-start">
				<div className="container">
					<h1 className="txt-gradient">Vantaggi</h1>

					<div className="flex g-1 space-between mb-3">
						<h2><span className="pictogram-top" /> Unicità</h2>
						<h2><span className="pictogram-top" /> Carattere</h2>
						<h2><span className="pictogram-top" /> Semplicità</h2>
						<h2><span className="pictogram-top" /> Velocità</h2>
						<h2><span className="pictogram-top" /> Risultati</h2>
						<h2><span className="pictogram-top" /> Consulenza</h2>
					</div>

					<div className="grid g-2 gtc-1-2-1">
						<h2 className="self-start mb-0">Perchè noi?</h2>
						<p className="mb-0">Siamo come una famiglia: sempre al tuo fianco, pronti a supportarti e a spingerti verso il successo! Ma soprattutto, siamo dei maghi del <strong>web design</strong>, capaci di creare siti web che sembrano provenire da un'altra dimensione.
						<br /><br />Non ci piacciono gli schemi preconfezionati o le scorciatoie, sviluppiamo il tuo <strong>sito web su misura</strong>, mettendo al primo posto le tue necessità e i tuoi obiettivi. E cosa c'è di meglio? Non dovrai perdere tempo sul tuo sito perché faremo tutto noi!
						<br /><br />E dopo? Non ti lasceremo mai solo! Ti forniremo tutto il <strong>supporto</strong> di cui hai bisogno per far sì che il tuo sito web funzioni sempre al massimo delle sue capacità.</p>

						<HashLink to="#contattaci" className="btn self-end">RICHIEDI INFO</HashLink>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Chi ci ha scelto</h1>
						<Link to="progetti" className="btn mb-3">ESPLORA I PROGETTI</Link>
					</div>

					<div className="grid g-3">
						{projectsList.slice(0, 4).map((project, idx) =>
							<ProjectOverlay
								key={idx}
								img={project.img}
								projectName={project.name}
								category={project.category}
								url={project.url}
							/>
						)}
					</div>
				</div>
			</section>
			
			<section className="bg-waveline">
				<div className="container txt-center">
					<h3>La nostra missione è mostrare al mondo la tua</h3>
					<h1 className="fs-100">Unicità</h1>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="news" />
				<div className="container">
					<div className="flex g-2 space-between">
						<h1>Ultime News</h1>
						<Link to="news" className="btn mb-3">VEDI TUTTE</Link>
					</div>

					<div className="grid g-2 gtc-3">
						{newsList.slice(0,3).map((news, idx) =>
							<ArticlePreview
								key={idx}
								img={news.imgs.v}
								title={news.title}
								slug={news.slug}
								date={news.date}
								category={news.category}
							/>
						)}
					</div>
				</div>
			</section>
			
			<span className="sr-only offset-nav" id="contattaci" />
			<SectionContact />
		</>
	);
}
