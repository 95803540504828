import { Link } from "react-router-dom";

export default function ArticlePreview({ img, title, slug, date, category }) {
	return (
		<article className="news-preview" style={{ '--bg-news-preview': `url(${img})` }}>
			<Link to={`/blog/${slug}`}>
				<time>{date}</time>
				<h2>{title}</h2>
				<div className="news-category">{category}</div>
			</Link>
		</article>
	);
}