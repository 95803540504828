import ContactForm from '../components/ContactForm';

export default function SectionContact({ bg=true }) {
	return (
		<section className={bg ? 'accent-end' : ''}>
			<div className="container">
				{bg &&
					<h1 className="txt-gradient">Contattaci</h1>
				}

				<div className="grid gtc-1-2 g-2">
					<ul className="self-start">
						<li>
							{bg ?
								<h3 className='mb-3'>Raccontaci il tuo progetto 🔥</h3> :
								<h3 className='mb-3'>Hai bisogno di un aiuto con il tuo progetto o sito web?</h3>
							}
						</li>
						<li>
							<h2><span className="pictogram" /> Design esclusivo</h2>
						</li>
						<li>
							<h2><span className="pictogram" /> Dinamicità e carattere</h2>
						</li>
						<li>
							<h2><span className="pictogram" /> Semplicità</h2>
						</li>
					</ul>

					<ContactForm />
				</div>
			</div>
		</section>
	);
}