import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import PageIndex from '../../components/PageIndex';
import paginaCorsiAIS from '../../imgs/news/pagina-corsi-ais.webp';

export default function SitoGratisSoccorritori() {
	return (
		<>
			<Helmet>
				<meta name="description" content="Scopri come abbiamo creato gratuitamente il nuovo sito web dell'Associazione Italiana Soccorritori di Bassano del Grappa, migliorando la loro presenza online e semplificando l'accesso ai corsi e ai servizi." />
				<meta name="keywords" content="sito web personalizzato di alta qualità, testimonianze reali e positive sul sito, inserire PayPal nel proprio sito, migliorare la presenza online, semplificareil processo di iscrizionenel sito web, sito facilmente navigabile, fiducia e attendibilità sito web, recensioni nel sito, difficoltà a iscriversi nel sito, difficoltà a trovare le informazioni sito web, creazione di un nuovo sito web, sfide e obiettivi del sito web, briefing, progetto web, assistenza medica, corsi formativi, supporto ospedaliero, associazione italiana soccorritori, come reagire in situazioni di emergenza, sito web gratuito, volontariato, sito web, sito web performante, sito web associazione, sito web ben progettato, sito professionale, bassano del grappa" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduzione',
						title: 'Introduzione'
					},
					{
						hash: 'nascita-progetto',
						title: 'La nascita del Progetto'
					},
					{
						hash: 'cliente',
						title: 'Il cliente'
					},
					{
						hash: 'risultati',
						title: 'La sfida e i Risultati Ottenuti'
					},
					{
						hash: 'conclusione',
						title: 'In conclusione'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduzione" />
				<p>In questo articolo, siamo entusiasti di condividere con te una storia speciale che rappresenta il nostro impegno verso la comunità e la passione che mettiamo nel nostro lavoro.</p>
				<p>Ci teniamo a presentare questo <strong>progetto web</strong> per mettere in evidenza l'importanza di sostenere associazioni come l'<strong>Associazione Italiana Soccorritori di Bassano del Grappa</strong> (AIS Bassano), che ogni giorno grazie ai suoi volontari e soccorritori offre un prezioso aiuto a molte persone.</p>
			</section>
			
			<section>
				<span className="sr-only offset-nav" id="cliente" />
				<h2>Il cliente</h2>
				<p>Nel cuore di <strong>Bassano del Grappa</strong>, c'è un gruppo di persone straordinarie che dedicano il loro tempo a diffondere la cultura del volontariato. Questo gruppo si dedica con costanza a promuovere e formare la popolazione sul <strong>come reagire in situazioni di emergenza</strong>. Organizzano corsi formativi, offrono supporto ospedaliero, forniscono assistenza medica in occasione di eventi e offrono servizi di trasporto per agevolare i cittadini con difficoltà a spostarsi autonomamente. Parliamo dell'<a href="https://aisbassano.org" target="_blank" rel="noreferrer">Associazione Italiana Soccorritori di Bassano del Grappa</a> (AIS Bassano), un'organizzazione di volontari che svolge un ruolo davvero importante nella nostra comunità.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="nascita-progetto" />
				<h2>La nascita del Progetto</h2>
				<p>Tutto è cominciato quando abbiamo partecipato a 1 dei <strong>corsi di primo soccorso</strong> organizzati dall'Associazione. Quest'esperienza ci ha davvero colpito, sia per le conoscenze trasmesse da medici, infermieri e personale qualificato, sia per l'attenzione con cui il corso era stato organizzato. Nessun altro corso a cui avevamo mai partecipato sul primo soccorso era stato così completo.</p>
				<p>L'Associazione aveva già un sito web in uso per le iscrizioni a un paio di corsi, ed è da lì che ci siamo iscritti. Tuttavia, abbiamo notato che il sito era stato trascurato e aveva bisogno di una revisione completa e molte persone avevano <strong>difficoltà ad iscriversi e a trovare le informazioni necessarie</strong>. Questo ci ha spinto a mettere a disposizione le nostre competenze per la <strong>creazione di un nuovo sito web</strong>, e l'abbiamo fatto <strong>gratuitamente</strong>. Questo piccolo gesto era il nostro modo di sostenere l'Associazione.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="risultati" />
				<h2>La sfida e i Risultati Ottenuti</h2>
				<p>Abbiamo lavorato con entusiasmo e impegno su questo progetto speciale, collaborando in modo stretto e attento con l'Associazione per comprenderne appieno le <strong>esigenze, le sfide e gli obiettivi</strong>.</p>
				<p>Durante la fase di <strong>briefing</strong>, abbiamo identificato le principali necessità che includevano:</p>
				<ul className="list mb-3">
					<li><strong>Migliorare la presenza online</strong> per trasmettere chiaramente la missione dell'Associazione</li>
					<li>Aggiungere tutti i nuovi corsi organizzati, <strong>semplificando il processo di iscrizione</strong></li>
					<li>Agevolare la richiesta dei servizi offerti</li>
					<li>Raggiungere un <strong>pubblico più vasto</strong>, fornendo informazioni e aggiornamenti rilevanti</li>
					<li>Promuovere con forza la missione e la cultura del volontariato</li>
					<li>Semplificare il processo di donazione per chi volesse sostenere l'Associazione</li>
					<li>Rendere il <strong>sito facilmente navigabile</strong> anche sui dispositivi mobili, come i telefoni cellulari</li>
					<li>Generare <strong>fiducia e attendibilità</strong> nei visitatori del sito</li>
				</ul>

				<div className="grid gtc-2-1 g-3 mb-3">
					<div>
						<p>Il risultato è stato un <strong>sito web personalizzato di alta qualità</strong>, progettato per mettere in evidenza la missione dell'Associazione e il suo ruolo essenziale nella comunità. Abbiamo reso il sito intuitivo e veloce da navigare, fornendo informazioni dettagliate e coinvolgenti.</p>
						<p>Per rendere l'accesso ai corsi e la richiesta di servizi ancora più agevoli, abbiamo creato una sezione di FAQ (Domande Frequenti) che fornisce risposte complete alle domande più comuni. Abbiamo inoltre <strong>semplificato le procedure di iscrizione</strong> ai corsi e di richiesta dei servizi attraverso un comodo modulo disponibile in fondo alla pagina.</p>
						<p>Per aumentare ulteriormente la <strong>fiducia e l'attendibilità tra i nuovi utenti</strong>, sono state raccolte <strong>recensioni</strong> sull'Associazione, nonché sui corsi e servizi offerti. Queste recensioni le abbiamo posizionate strategicamente nelle varie pagine dedicate ai corsi e ai servizi, offrendo <strong>testimonianze reali e positive</strong> dalla comunità.</p>
						<p>Infine abbiamo anche creato una pagina dedicata alle donazioni. Qui, i sostenitori possono scegliere tra vari metodi di donazione, inclusi <strong>PayPal</strong>, rendendo il processo di donazione facile e veloce per chiunque voglia contribuire al prezioso lavoro dell'Associazione.</p>
					</div>

					<img src={paginaCorsiAIS} alt="Pagina Corso Bld del sito AIS Bassano • Un Impegno per la comunità: il nostro lavoro Gratuito per l'Associazione Italiana Soccorritori • News Travolgi" />
				</div>

				<p>Il nostro nuovo sito web permette all'AIS Bassano di mostrare al mondo il loro prezioso lavoro, i corsi che tengono e i servizi che offrono semplicando il processo di iscrizione/richiesta e di diffondere informazioni cruciali sul soccorso e sull'associazione stessa. Siamo orgogliosi di contribuire al loro nobile sforzo e speriamo che questo nuovo sito possa aiutare l'Associazione a raggiungere un pubblico ancora più ampio e a continuare la sua importante missione.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="conclusione" />
				<h2>In conclusione</h2>
				<p>Questa esperienza ci ha rafforzato nella convinzione di quanto sia gratificante mettere le nostre competenze al servizio degli altri. Siamo determinati a essere un esempio di come il <strong>mondo del business</strong> possa contribuire a migliorare la società in cui operiamo.</p>
				<p>Ti invitiamo a esplorare il <a href="https://aisbassano.org" target="_blank" rel="noreferrer">nuovo sito web</a> dell'Associazione Italiana Soccorritori di Bassano del Grappa e a scoprire l'eccezionale lavoro che svolgono. Sostenere organizzazioni come questa è un modo tangibile per ognuno di noi di <strong>fare la differenza</strong>, non solo nella nostra comunità, ma anche a livello globale e per il bene del nostro futuro.</p>
				<p>Grazie per aver dedicato del tempo a leggere questa storia. Se vuoi <strong>migliorare la tua presenza online</strong>, <HashLink to="#contattaci">Contattaci Ora</HashLink> per iniziare il Tuo viaggio verso un <strong>sito web autentico e personalizzato</strong>. Siamo qui per trasformare le tue idee in realtà!</p>
			</section>
		</>
	);
}