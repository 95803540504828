import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import newsList from '../data/news';
import SectionPageTitle from '../components/SectionPageTitle';
import SocialShare from '../components/SocialShare';
import Sidebar from '../components/Sidebar';
import SectionContact from '../components/SectionContact';
import PageNotFound from './PageNotFound';

export default function LayoutArticolo() {
	const { newsName } = useParams(),
			news = newsList.filter(news => news.slug.includes(newsName))[0];
			
	if (news) {
		return (
			<>
				<Helmet>
					<title>{news.title} &bull; News &bull; Travolgi</title>
				</Helmet>

				<SectionPageTitle
					pageTitle={news.title}
					subTitle={`${news.date} • ${news.category}`}
					bgImg={news.imgs}
				/>

				<section className="container grid-sidebar">
					<article>
						{news.content}
						
						<SocialShare title={news.title} img={news.imgs.v} />
					</article>

					<Sidebar />
				</section>

				<span className="sr-only offset-nav" id="contattaci" />
				<SectionContact />
			</>
		);
	} else {
		return <PageNotFound />
	}
}
