import { Helmet } from 'react-helmet';
import projectsList from '../data/projects';
import SectionPageTitle from '../components/SectionPageTitle';
import ProjectOverlay from '../components/ProjectOverlay';
import SectionContact from '../components/SectionContact';

export default function Progetti() {
	return (
		<>
			<Helmet>
				<title>Chi ci ha scelto: Progetti &bull; Travolgi &bull; Sii unico, sii travolgente!</title>
				<meta name="description" content="Esplora i nostri progetti e scopri come possiamo aiutarti a raggiungere i tuoi obiettivi online. Sarai il prossimo sulla lista dei nostri lavori?" />
				<meta name="keywords" content="progetto sito web, lavori di web design, lavori, design siti web, obiettivi online, clienti, progetti realizzati, progetti, bassano del grappa" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Chi ci ha scelto'} 
				subTitle={'I nostri Progetti'}
			/>

			<section className="container">
				<p className="mb-3">Esplora tutti i nostri progetti e le sfide più affascinanti che abbiamo affrontato: scopri cosa dicono del nostro lavoro e come possiamo aiutarti a raggiungere i tuoi <strong>obiettivi online</strong>. Sarai il prossimo sulla lista dei nostri lavori?</p>

				<div className="grid g-3">
					{projectsList.map((project, idx) =>
						<ProjectOverlay
							key={idx}
							img={project.img}
							projectName={project.name}
							category={project.category}
							url={project.url}
						/>
					)}
				</div>
			</section>

			<SectionContact />
		</>
	);
}