import { Helmet } from 'react-helmet';
import PageIndex from '../../components/PageIndex';
import ricercaInternet from '../../imgs/news/ricerca-internet.svg';
import erroriSito from '../../imgs/news/errori-sito.svg';

export default function PercheAvereUnSito() {
	return (
		<>
			<Helmet>
				<meta name="description" content="Vantaggi e svantaggi del sito web, le persone cercano tutto su internet: ecco perchè è fondamentale avere una vetrina online ben fatta" />
				<meta name="keywords" content="perché avere un sito web, sito vetrina, landing page, blog, ecommerce, sito web, sito web performante, sito wordpress, sito web aziendale, sito web ben progettato, errore sito, malfunzionamenti sito, sito web responsive, responsive, sito web dal telefono, responsive design, design, sito personalizzato, sito professionale, vetrina online, servizi essenziali sito, dominio, nome a dominio, hosting, comunicazione, comunicazione coi clienti, brand awareness, risparmiare tempo, branding, web agency, sviluppatore web, professionista web, competente e aggiornato, bassano del grappa" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduzione',
						title: 'Introduzione'
					},
					{
						hash: 'come-funziona',
						title: 'Ma come funziona un sito web?'
					},
					{
						hash: 'convenienza',
						title: 'Conviene o no avere un sito web?'
					},
					{
						hash: 'come-sfruttarlo',
						title: 'Come posso sfruttare il mio sito web?'
					},
					{
						hash: 'vantaggi-svantaggi',
						title: 'Vantaggi vs svantaggi'
					},
					{
						hash: 'tutti-dicono',
						title: 'Tutti mi dicono che è solo una spesa, perché?'
					},
					{
						hash: 'conclusione',
						title: 'In conclusione'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduzione" />
				<p>Avere un sito web offre molti vantaggi, motivo per cui ne esistono varie tipologie come ad esempio <strong>il vetrina, la landing page, il blog, l’ecommerce, il social network, i portali web</strong> ecc.</p>
				<p>Le persone, al giorno d’oggi, cercano su internet qualsiasi cosa e non esserci equivale a non esistere. Avere <strong>un sito web è sicuramente fondamentale</strong> per una qualsiasi attività... e direi obbligatorio se i concorrenti di quest’ultima ne hanno già uno.</p>
			</section>
				
			<section>
				<span className="sr-only offset-nav" id="come-funziona" />
				<h2>Ma come funziona un sito web?</h2>
				<p>Un <strong>sito web</strong> non è altro che una raccolta di file interconnessi tra loro che possono svolgere molte funzioni contemporaneamente, facendoci <strong>risparmiare molto tempo</strong>. Per poter funzionare ed essere visto dagli utenti tramite i loro dispositivi connessi a internet però, un sito web necessita di alcuni <strong>servizi essenziali</strong> come:</p>
				<ul className="list mb-0">
					<li>un <strong>nome a dominio</strong>, <em>come travolgi.it</em></li>
					<li>un servizio di <strong>hosting</strong> dove caricare i file</li>
					<li>un <strong>database</strong> per i dati (se necessario)</li>
					<li>delle <strong>email dedicate</strong>, <em>come test@travolgi.it (opzionali)</em></li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="convenienza" />
				<h2>Conviene o no avere un sito web?</h2>
				<p className="mb-3">La convenienza e l'utilità di possederne uno è strettamente legata alle tue necessità, ai tuoi obiettivi e alla concorrenza che ti ritrovi ad affrontare. Vedilo come uno <strong>strumento di comunicazione e divulgazione per arrivare più facilmente e velocemente ai tuoi obiettivi</strong>.</p>

				<div className="grid g-2 mb-1">
					<img src={ricercaInternet} className="w-60" alt="La gente usa internet per trovare informazioni • Perchè dovresti avere un sito web • News Travolgi" />

					<div>
						<p>Le persone utilizzano giornalmente internet per trovare informazioni, attività, recensioni e consigli, prodotti e servizi, per scegliere quali prodotti acquistare e dove acquistarli.</p>
						<p>Se vuoi essere competitivo devi avere una <strong>vetrina online</strong> ben fatta che porti i tuoi <strong>clienti e potenziali clienti</strong> a conoscerti e sceglierti 7 giorni su 7 e h24.</p>
					</div>
				</div>
			</section>

			<section>
				<span className="sr-only offset-nav" id="come-sfruttarlo" />
				<h2>Come posso sfruttare il mio sito web?</h2>
				<ul className="list mb-0">
					<li>Creando <strong>brand awareness</strong>, ovvero far conoscere e trovare la tua attività</li>
					<li><strong>Pubblicizzando e vendendo</strong> prodotti, servizi e consulenze</li>
					<li>Semplificando la richiesta di informazioni</li>
					<li>Velocizzando e semplificando la <strong>comunicazione coi clienti</strong></li>
					<li>Creando una <strong>community</strong> di persone interessate</li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="vantaggi-svantaggi" />
				<h2>Vantaggi vs svantaggi</h2>
				<h3>Vantaggi</h3>
				<ul className="list">
					<li><strong>Risparmi tempo</strong> informando, educando e rispondendo ai clienti e/o potenziali clienti</li>
					<li><strong>Abbatti barriere di tempo e spazio</strong> avvicinando nuovi clienti da tutto il mondo</li>
					<li>Sei <strong>sempre aperto</strong> e qui il tuo sito web fa la differenza perchè <strong>non ha giorni e orari di chiusura</strong></li>
					<li><strong>Elimini i non clienti</strong> perchè capiscono cosa fai e che non sei quello che stavano cercavano</li>
					<li><strong>Recuperi informazioni</strong> scoprendo nuove richieste o mancanze nel tuo servizio</li>
					<li><strong>Comunichi velocemente</strong> idee e novità</li>
				</ul>

				<h3>Svantaggi</h3>
				<ul className="list mb-0">
					<li>Richiede <strong>abilità ed esperienza</strong>, devi sapere come si fa e farlo bene</li>
					<li>Hai dei <strong>costi annuali</strong> per il dominio, le caselle di posta e un hosting performante</li>
					<li>Potresti <strong>raggiungere troppa gente</strong> e non riuscire a rispondere e a gestire tutti quanti</li>
					<li><strong>Devi cambiare mindset</strong> passando dal <em>“io faccio questo per i miei clienti”</em> a <strong>“cosa cercano i miei clienti”</strong> così da intuire che ricerche potrebbero fare sul web</li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="tutti-dicono" />
				<h2>Tutti mi dicono che è solo una spesa, perché?</h2>
				<p>Le cause possono essere molte ma ecco alcune domande che potrebbero darti qualche risposta:</p>

				<div className="grid g-2 mb-3">
					<ul className="list">
						<li>Il <strong>design</strong> e la struttura del sito web sono <strong>ben progettati</strong>? É carino da vedere e facile da usare?</li>
						<li>Il sito web ha delle foto e dei colori armoniosi? I testi sono privi di errori ortografici?</li>
						<li>Se apro il <strong>sito web dal telefono</strong>, riesco a visualizzare tutto e a navigare senza problemi? E da computer?<span className="sr-only">É un sito web responsive?</span></li>
						<li>Non ha nessun <strong>errore</strong> che può innervosire gli utenti e farli scappare?</li>
						<li>Se qualcuno lo cerca su Google, il sito appare nei <strong>risultati</strong>?</li>
					</ul>
					
					<img src={erroriSito} className="w-60" alt="Malfunzionamenti del sito web • Perchè dovresti avere un sito web • News Travolgi" />
				</div>

				<p>Se hai risposto <b>NO</b> a una sola di queste domande è normale che il sito in questione sia diventato più una spesa che un guadagno, come puoi notare possono essere molti i motivi e a dirla tutta avere un sito web vecchio, non visibile da telefono, lentissimo nel caricamento, pieno di errori o con inseriti dei dati obsoleti è controproducente tanto che… è meglio non averlo proprio.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="conclusione" />
				<h2>In conclusione</h2>
				<p>Se vuoi avere un <strong>sito web performante che ti dia qualche risultato</strong>, per prima cosa, non devono esserci <strong>malfunzionamenti</strong>, design o dati obsoleti.</p>
				<p>Piccoli dettagli fanno un mare di differenza e avere una <strong>figura specializzata che se ne occupa per te</strong> è la scelta migliore che puoi fare: un <strong>professionista tecnicamente competente e aggiornato</strong> che si occupa in piena autonomia del tuo sito web non ti farà perdere né tempo né denaro.</p>
			</section>
		</>
	);
}