import { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
	const form = useRef();
	const initValue = { name:'', email: '', message:'', agree: 'false' };
	const [formVals, setFormVals] = useState(initValue),
			[isAgree, setIsAgree] = useState(false),
			[formErrs, setFormErrs] = useState({}),
			[isSubmit, setIsSubmit] = useState(false),
			[isSent, setIsSent] = useState(false);

	const handleChange = e => {
		const { id, value } = e.target;
		setFormVals({ ...formVals, [id]: value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		setFormErrs(validate(formVals));
		setIsSubmit(true);
	};

	useEffect(() => {
		if(Object.keys(formErrs).length === 0 && isSubmit) {
			emailjs.sendForm('service_h6s2m8s', 'template_26i4wqw', form.current, 'cIpXspQX-aee8phaF')
				.then(result => {
					console.log(result.text);
					setFormVals(initValue);
					setIsSent(true);
					setFormErrs({});
					setIsSubmit(false);
					setIsAgree(false);
				}, error => {
					console.log(error.text);
					setIsSubmit(false);
					setIsSent(false);
				}
			);
		}
	}, [formErrs, isSubmit]);
	
	useEffect(() => {
		if(isSent) {
			const msgSent = setTimeout(()=>setIsSent(false), 5000);
			return () => clearTimeout(msgSent);
		}
	}, [isSent]);

	const validate = (vals) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		
		if (!vals.name) {
			errors.name = 'Inserisci il tuo nome.';
		} else if (vals.name.length < 4) {
			errors.name = 'Inserisci minimo 4 caratteri.';
		}

		if (!vals.email) {
			errors.email = 'Inserisci la tua email.';
		} else if (!regex.test(vals.email)) {
			errors.email = 'Formato email non valido!';
		}

		if (vals.agree === 'false') {
			errors.agree = 'Devi accettare la Privacy Policy.';
		}
		
		return errors;
	};
	
	return (
		<form ref={form} onSubmit={handleSubmit}>
			<small>Campi obbligatori contrassegnati con *</small>

			<input
				id="domainName"
				name="domainName"
				type="text"
				defaultValue="https://travolgi.it"
				className="sr-only"
			/>

			<div className="grid g-1">
				<div className="mb-1 w-100">
					<label htmlFor="name" className="sr-only">Nome Cognome *</label>
					<input
						id="name"
						name="name"
						type="text"
						placeholder="Nome Cognome *"
						required
						value={formVals.name}
						onChange={handleChange}
					/>
					{formErrs.name &&
						<p className="txt-danger fadein">{formErrs.name}</p>
					}
				</div>
					
				<div className="mb-1 w-100">
					<label htmlFor="email" className="sr-only">La tua Email *</label>
					<input
						id="email"
						name="email"
						type="email"
						placeholder="La tua Email *"
						required
						value={formVals.email}
						onChange={handleChange}
					/>
					{formErrs.email &&
						<p className="txt-danger fadein">{formErrs.email}</p>
					}
				</div>
			</div>

			<label htmlFor="message" className="sr-only">Il tuo Messaggio *</label>
			<textarea
				className="mb-1"
				id="message"
				name="message" 
				rows="8"
				placeholder="Il tuo Messaggio"
				value={formVals.message}
				onChange={handleChange}
			/>

			<input
				type="checkbox"
				id="agree"
				name="agree"
				value={isAgree === true ? 'false' : 'true'}
				checked={isAgree}
				required
				onClick={() => setIsAgree(!isAgree)}
				onChange={handleChange}
			/>
			<label htmlFor="agree">Ho letto e accettato la <a href="https://www.iubenda.com/privacy-policy/51620879/legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a> *.</label>
			{formErrs.agree &&
				<p className="txt-danger fadein">{formErrs.agree}</p>
			}

			{isSent &&
				<h2 className="txt-success pbk-1 fadein">Invio riuscito! Ti risponderemo al più presto.</h2>
			}

			<button type="submit" disabled={isSubmit ? true : false}>Invia ora</button>
		</form>
	);
}