import { Link } from "react-router-dom";
import SocialLinks from "./SocialLinks";
import logo from '../imgs/travolgi-logo.webp';

export default function Footer() {
	const currentDate = new Date().getFullYear();
	return (
		<footer>
			<div className="container">
				<div className="txt-center">
					<img src={logo} alt="Travolgi logo" />
					<p>&copy; Travolgi {currentDate}</p>
				</div>

				<div>
					<h2>Perchè noi?</h2>
					<p>Ti aiutiamo a comunicare ed espandere la tua attività online, a raggiungere nuovi clienti e ad avere il sito web che hai sempre desiderato in modo semplice e veloce</p>
				</div>

				<ul>
					<li>
						<address>Bassano del Grappa (VI)</address>
					</li>
					<li> PLLNNA65R60A703G</li>
					<li>
						<a href="https://www.iubenda.com/privacy-policy/51620879/legal" target="_blank" rel="noreferrer">Privacy Policy</a>
					</li>
					<li className="mb-1">
						<Link to='/cookie-policy'>Cookie Policy</Link>
					</li>
					<li>Seguici su:</li>
					<SocialLinks />
				</ul>
			</div>
		</footer>
	);
}
