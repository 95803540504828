import { Helmet } from 'react-helmet';
import PageIndex from '../../components/PageIndex';
import categorieCookie from '../../imgs/news/categorie-cookie.webp';

export default function PrivacyCookieNorma() {
	return (
		<>
			<Helmet>
				<meta name="description" content="Ecco cosa devi fare per avere un sito conforme: il consenso dei cookie e la privacy policy sono obbligatori ma attenzione: da soli non sono sufficienti" />
				<meta name="keywords" content="conformità alla privacy, gestione del consenso, registro storico dei consensi, blocco automatico dei cookie, generatore gratuito policy, privacy, dati personali, pagine informative, normative, leggi privacy,policy, banner dei cookie, banner di consenso, conformità gdpr, banner ostacolo, cookie tecnici, categorie di cookie, sanzioni, multe, consenso dei cookie, cookie, cookies, bloccare gli script, blocco dei cookie, revoca del consenso, salvare preferenze cookie, tipologia cookie, gdpr, sito web sprovvisto di privacy e cookie, sito web non conforme, sito non a norma, sito web conforme, regolamento generale sulla protezione dei dati, trattamento dei dati, sito web, sito wordpress, sito web aziendale, sito web a norma di legge, bassano del grappa" />
			</Helmet>

			<PageIndex
				links={[
					{
						hash: 'introduzione',
						title: 'Introduzione'
					},
					{
						hash: 'regole-base',
						title: 'Quali sono le regole di base?'
					},
					{
						hash: 'comportamenti-scorretti',
						title: 'Cosa non è consentito?'
					},
					{
						hash: 'sanzioni',
						title: 'Cosa mi aspetta se non sono a norma?'
					},
					{
						hash: 'tools',
						title: 'I migliori Strumenti per la Privacy e la Gestione dei Cookie'
					},
					{
						hash: 'conclusione',
						title: 'In conclusione'
					}
				]}
			/>

			<section>
				<span className="sr-only offset-nav" id="introduzione" />
				<p>Il <strong>GDPR</strong> o, <strong>regolamento generale sulla protezione dei dati</strong>, è una legge europea del 2016 applicabile a partire dal 25 Maggio 2018 che regola il modo in cui le aziende e le organizzazioni devono trattare i <strong>dati personali</strong> delle persone.</p>
				<p>In altre parole, tutela i diritti e le libertà fondamentali delle persone fisiche, fornendo loro il controllo sull'utilizzo dei propri dati e stabilendo i requisiti per il <strong>trattamento dei dati</strong>, la documentazione da produrre, la trasparenza, la conservazione e il consenso delle preferenze.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="regole-base" />
				<h2>Quali sono le regole di base?</h2>
				<p>Il banner per il <strong>consenso dei cookie è obbligatorio</strong> ma attenzione: da solo non è sufficiente. Il consenso dato dall'utente deve sempre <strong>essere inequivocabile</strong> e connesso a una sua chiara azione attiva e consapevole, e per essere conformi si deve:</p>
				<ul className="list">
					<li>mostrare un <strong>banner di consenso</strong> non invasivo con:
						<ul>
							<li>un pulsante <em>ACCETTA</em> che permette tutti <strong>cookie</strong></li>
							<li>un pulsante <em>RIFIUTA</em> che permette unicamente i <strong>cookie tecnici</strong></li>
							<li>un pulsante <em>X</em> che permette di chiudere il banner senza prendere una decisione, <em>equivale al pulsante RIFIUTA</em></li>
							<li>un pulsante <em>PERSONALIZZA</em> che fornisce l'opzione di selezione specifica per accettare o rifiutare le varie <strong>categorie di cookie</strong></li>
						</ul>
					</li>
					<li>mostrare i <strong>link alle policy complete</strong></li>
					<li>informare con un <strong>linguaggio semplice e chiaro</strong></li>
					<li>tradurre le informazioni automaticamente in base alle lingue usate nel sito</li>
					<li><strong>bloccare gli script di terze parti</strong> fino a quando l'utente non ha dato il suo consenso</li>
					<li><strong>premettere la revoca del consenso</strong> dato in qualsiasi momento</li>
					<li><strong>salvare le preferenze</strong> senza effettuare nuove richieste a ogni accesso</li>
				</ul>

				<div className="grid g-2">
					<div>
						<p>Le scelte prese in fase di accettazione o negazione definiscono quali cookie possono essere utilizzati, ciò significa che <strong>senza consenso non è consentito l'uso di alcun tipo di cookie</strong>. Dichiarata la preferenza il <strong>sito web</strong> deve adeguarsi e usare solamente i cookie accettati dall'utente, impedendo l'installazione degli altri.</p>
						<p>Ogni utente può quindi accettare l'installazione di solo alcune <strong>categorie di cookie</strong> o navigare per l'intero sito senza interagire con il banner e senza alcun cookie. Ciò viene spesso ignorato rendendo il cookie banner inutile.
						<br /><em className="txt-danger">Attenzione! Quasi tutti i plugin di wordpress creano un semplice banner con un pulsante accetta e non adempiono a tutte queste funzionalità.</em></p>

						<p>Inoltre le <strong>pagine informative</strong> complete devono fornire in maniera chiara e completa tutte le indicazioni riguardo il <strong>trattamento dei dati e la tipologia dei cookie</strong> ed essere sempre raggiungibili attraverso dei link, tipicamente posizionati nel footer del sito.</p>
					</div>

					<img src={categorieCookie} className="br-1" alt="Opzione di selezione specifica per accettare o rifiutare le varie categorie di cookie • Privacy policy e cookie banner: sicuro di essere a norma? • News Travolgi" />
				</div>
			</section>
				
			<section>
				<span className="sr-only offset-nav" id="comportamenti-scorretti" />
				<h2>Cosa non è consentito?</h2>
				<p>Il consenso dell'utente deve essere opzionale e mai derivato da una forzatura, perciò alcuni comportamenti sono ritenuti scorretti e vanno assolutamente evitati:</p>
				<ul className="list mb-0">
					<li><strong>Swipe e scroll</strong>: spostare il cursore o scorrere la pagina non rappresentano un consenso idoneo</li> 
					<li><strong>Cookie wall</strong>: un <em>banner “ostacolo”</em> che blocca l'accesso al sito web e obbliga l'utente alla scelta forzata di accettare tutti i cookie non è conforme</li>
					<li><strong>Ripetizione della richiesta</strong>: la scelta dell'utente va richiesta solo 1 volta e registrata quando effettuata, poi non va più sollecitata</li>
				</ul>
			</section>

			<section>
				<span className="sr-only offset-nav" id="sanzioni" />
				<h2>Cosa mi aspetta se non sono a norma?</h2>
				<p>Le <strong>sanzioni</strong> al proprietario possono essere molto alte quando il suo <strong>sito web è sprovvisto di privacy e cookie policy</strong>, o le medesime non sono completamente conformi:</p>
				<ul className="list">
					<li>da 6.000 a 38.000 euro per mancante informativa o informativa inidonea</li>
					<li>da 10.000 a 130.000 euro per l'uso dei cookie senza il consenso dell'utente</li>
					<li>da 25.000 a 130.000 euro per mancante o incompleta notificazione al Garante</li>
				</ul>

				<p>Le sanzioni possono arrivare a un massimo di 20 milioni di euro o al 4% del fatturato annuo dell'attività.
				<br/>Puoi vedere tutte le multe fatte finora in Europa e Italia ai sensi del GDPR su <a href="https://www.enforcementtracker.com" target="_blank" rel="noopener noreferrer">enforcementtracker.com</a>.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="tools" />
				<h2>I migliori Strumenti per la Privacy e la Gestione dei Cookie</h2>
				<p>Mantenere la tua presenza online in linea con le normative sulla Privacy e i Cookie è cruciale per evitare pesanti sanzioni e garantire la trasparenza e la fiducia dei tuoi visitatori. Di seguito ti consigliamo 2 strumenti affidabili che possono semplificare il processo di conformità e aiutarti a evitare possibili problemi legali:</p>

				<h3>Iubenda</h3>
				<p>Se stai cercando una soluzione completa per la conformità alla privacy, <a href="https://iubenda.refr.cc/travolgi" target="_blank" rel="noreferrer">Iubenda</a> è il tuo alleato perfetto. Creato da un team di avvocati, offre soluzioni per adeguare i tuoi siti web alle normative di più paesi e legislazioni, diventando un vero e proprio salvagente per le aziende di tutte le dimensioni. Le caratteristiche principali sono:</p>
				<ul className="list">
					<li>Generatore di Privacy policy, Cookie Policy e <strong>Termini e Condizioni</strong> dettagliate e su misura</li>
					<li><strong>Banner dei Cookie</strong> personalizzabili</li>
					<li>Scansione automatica dei Cookie</li>
					<li>Registro preferenze Cookie e Consensi</li>
					<li>Aggiornamenti automatici sulle normative</li>
					<li><strong>Conformità GDPR</strong> avanzata</li>
					<li>Costo medio-alto</li>
				</ul>
				<p>Iubenda offre anche un piano gratuito con funzionalità limitate, ma per essere a norma avrai bisogno di acquistare un piano a pagamento. Tuttavia, la sicurezza e la conformità che offre possono valerne davvero la pena.</p>
				<p>Utilizzando il nostro link <a href="https://iubenda.refr.cc/travolgi" target="_blank" rel="noreferrer">Iubenda</a> avrai per il primo anno lo sconto del 10% sul tuo piano. <a href="https://iubenda.refr.cc/travolgi" target="_blank" rel="noreferrer">Clicca qui ora</a> e approfitta di questo sconto.</p>

				<h3>CookieYes</h3>
				<p>Se desideri un modo semplice ed efficace per gestire i Cookie sul tuo sito web, <a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">CookieYes</a> è la risposta alle tue esigenze. Questa  soluzione intuitiva e leggera gestisce tutto l'aspetto dei cookie per la conformità alla privacy e ti offre anche la generazione gratuita della tua privacy policy. Le caratteristiche principali sono:</p>
				<ul className="list">
					<li>Banner personalizzabile e <strong>gestione del Consenso</strong> intuitiva</li>
					<li>Controllo granulare dei cookie e <strong>registro storico dei consensi</strong></li>
					<li>Scansione avanzata del sito web e <strong>blocco automatico dei cookie</strong></li>
					<li><strong>Generatore gratuito</strong> di Privacy Policy e Cookie Policy</li>
					<li>Conformità GDPR</li>
					<li>Costo contenuto</li>
				</ul>
				<p><a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">CookieYes</a> parte da un piano gratuito con funzionalità limitate per tutti i siti web con meno di 100 pagine per passare poi ai piani a pagamento più dettagliati. A differenza di Iubenda è meno specifico per le privacy policy ma offre molte funzionalità utili già nel piano gratuito, il che lo rende un ottimo strumento se si ha un budget limitato. <a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">Clicca qui ora</a> per saperne di più.</p>

				<p>Scegli lo strumento per mantenere il tuo sito web al sicuro che meglio si adatta alle tue esigenze e assicurati di utilizzarlo correttamente per garantire la massima trasparenza e la migliore esperienza possibile ai tuoi visitatori. Hai bisogno di aiuto per integrare questi strumenti nel tuo sito web? <a href="#contattaci">Scrivici ora!</a> Saremo felici di aiutarti.</p>
			</section>

			<section>
				<span className="sr-only offset-nav" id="conclusione" />
				<h2>In conclusione</h2>
				<p>La <strong>conformità alla Privacy</strong> è un processo continuo ed è quindi importante rimanere sempre aggiornati sulle leggi e le regolamentazioni in evoluzione. É sempre meglio far gestire questa materia a un <strong>professionista aggiornato e tecnicamente competente</strong>, perché rendere un <strong>sito web conforme</strong> non è affatto facile ed è sempre importante seguire gli ultimi aggiornamenti delle <strong>normative</strong>.</p>
			</section>
		</>
	);
}