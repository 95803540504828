import { Helmet } from 'react-helmet';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionContact from '../components/SectionContact';

export default function Contattaci() {
	return (
		<>
			<Helmet>
				<title>Contattaci &bull; Travolgi &bull; Sii unico, sii travolgente!</title>
				<meta name="description" content="Hai bisogno di un aiuto con il tuo progetto o sito web? Compila i campi qui sotto, lasciaci un messaggio e ti risponderemo il prima possibile!" />
				<meta name="keywords" content="travolgi, contatti, progetto, aiuto web, aiuto, lasciaci un messaggio, messaggio, perché avere un sito web, sito web, sito web responsive, responsive, bassano del grappa" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Contattaci'} 
				subTitle={'Parlaci del tuo progetto'}
			/>

			<p className="sr-only">Hai bisogno di un aiuto con il tuo progetto o sito web? Compila i campi qui sotto, lasciaci un messaggio e ti risponderemo il prima possibile!</p>

			<SectionContact
				bg={false}
			/>
		</>
	);
}