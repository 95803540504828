import { Helmet } from 'react-helmet';

export default function CookiePolicy() {
	return (
		<>
			<Helmet>
				<title>Cookie Policy &bull; Travolgi &bull; Sii unico, sii travolgente!</title>
			</Helmet>

			<section className="container">
				<h1>Cookie Policy</h1>
				<p>Questa Politica sui Cookie spiega cosa sono i cookie e come sono utilizzati, i tipi di cookie utilizzati, i dati raccolti tramite i cookie, come queste informazioni vengono utilizzate e come gestire le impostazioni dei cookie.</p>

				<h2>Cosa Sono i Cookies?</h2>
				<p>I cookie sono piccoli file di testo utilizzati per memorizzare piccole informazioni. Vengono memorizzati sul dispositivo dell'Utente quando il sito web viene caricato sul suo browser. Questi cookie aiutano a far funzionare correttamente il sito web, renderlo più sicuro, fornire una migliore esperienza utente e capire come si comporta il sito web, analizzando ciò che funziona e dove è necessario migliorare.</p>
				
				<h2>Utilizzo dei Cookie</h2>
				<p>Come la maggior parte dei servizi online, il nostro sito web utilizza cookie di prima parte e di terze parti per diverse finalità. I cookie di prima parte sono principalmente necessari per far funzionare correttamente il sito web e non raccolgono alcun dato identificabile personalmente. I cookie di terze parti hanno principalmente lo scopo di capire come il sito web si comporta, come l'Utente interagisce con il nostro sito web, mantenere sicuri i nostri servizi, fornire annunci pubblicitari pertinenti per l'Utente, offrire un'esperienza utente migliore e migliorata e velocizzare le future interazioni dell'Utente con il nostro sito web.</p>
				
				<h2>Tipi di Cookie Utilizzati</h2>
				<div className="cky-audit-table-element" />
				
				<h2>Gestire le Preferenze dei Cookie</h2>
				<p>L'Utente può modificare le sue preferenze sui Cookie in qualsiasi momento cliccando sul pulsante qui sotto. Questo gli permetterà di tornare al banner di consenso sui cookie e cambiare le sue preferenze o revocare il suo consenso immediatamente.</p>
				<a className="cky-banner-element">Impostazioni dei Cookie</a>
				<p>Inoltre, diversi browser offrono metodi diversi per bloccare ed eliminare i cookie utilizzati dai siti web. L'Utente può cambiare le impostazioni del suo browser per bloccare/eliminare i cookie, invitiamo l'Utente a consultare i documenti di supporto ufficiali del suo browser per ulteriori informazioni.</p>
				<p>Ultima modifica: 4 Settembre 2023</p>
				
				<small>Cookie Policy generata da <a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">CookieYes</a>.</small>
			</section>
		</>
	);
}