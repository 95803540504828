import { Helmet } from 'react-helmet';
import newsList from '../data/news';
import SectionPageTitle from '../components/SectionPageTitle';
import ArticlePreview from '../components/ArticlePreview';
import SectionContact from '../components/SectionContact';

export default function Blog() {
	return (
		<>
			<Helmet>
				<title>News &bull; Travolgi &bull; Sii unico, sii travolgente!</title>
				<meta name="description" content="Rimani aggiornato attraverso le news di Travolgi: qui trovi tutte le risposte alle tue perplessità e tutti gli aggiornamenti e le novità che riguardno il web" />
				<meta name="keywords" content="news, notizie, aggiornamenti, novità, web, articoli, perplessità, risposte, norme, travolgi, perché avere un sito web, sito web, sito wordpress, sito web aziendale, sito web responsive, responsive, sito web a norma di legge, bassano del grappa" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'News'} 
				subTitle={'Rimani aggiornato'}
			/>

			<section className="container grid g-2 gtc-3">
				<p className="sr-only">Rimani aggiornato attraverso le news di Travolgi: qui trovi tutte le risposte alle tue perplessità e tutti gli aggiornamenti e le novità che riguardno il web.</p>

				{newsList.map((news, idx) =>
					<ArticlePreview
						key={idx}
						img={news.imgs.v}
						title={news.title}
						slug={news.slug}
						date={news.date}
						category={news.category}
					/>
				)}
			</section>

			<SectionContact />
		</>
	);
}