import { Helmet } from 'react-helmet';
import { HashLink } from 'react-router-hash-link';
import SectionPageTitle from '../components/SectionPageTitle';
import SectionContact from '../components/SectionContact';
import mission from '../imgs/mission.webp';


export default function ChiSiamo() {
	return (
		<>
			<Helmet>
				<title>Chi siamo &bull; Travolgi &bull; Sii unico, sii travolgente!</title>
				<meta name="description" content="Dimentica gli schemi preconfezionati e le scorciatoie: sviluppiamo il tuo sito web su misura mettendo sempre al primo posto le tue necessità e i tuoi obiettivi." />
				<meta name="keywords" content="approccio personalizzato, sito web su misura, unico sito web, sviluppo siti web, siti web, successo online, sviluppatori web, bassano del grappa" />
			</Helmet>

			<SectionPageTitle
				pageTitle={'Chi siamo'} 
				subTitle={'La nostra storia'}
			/>

			<section className="container grid g-2">
				<div className="fingerprint flex">
					<img src={mission} alt="Chi Siamo • Travolgi" />
				</div>

				<article>
					<h1>Chi <span className="txt-gradient">Siamo</span></h1>
					<h2>Molto più di un team di sviluppatori web</h2>
					<p>Siamo come una famiglia, sempre al tuo fianco, pronti a supportarti e a spingerti verso il <strong>successo</strong>... in grado di creare <strong>siti web</strong> che sembrano provenire da un'altra dimensione. La nostra missione è semplice ma potente: far <strong>emergere la tua personalità</strong> e creare un sito web che sia tanto unico quanto te.</p>
					<p>Dimentica gli schemi preconfezionati e le scorciatoie. Noi sviluppiamo il tuo <strong>sito web su misura</strong>, facile da usare ma sopratutto funzionale, mettendo sempre al primo posto le tue <strong>necessità e i tuoi obiettivi</strong>. La parte migliore? Non dovrai perdere tempo a preoccuparti del tuo sito, perché faremo tutto noi al posto tuo!</p>
					<p className="mb-3">Con il nostro <strong>approccio personalizzato</strong>, non solo garantiamo un <strong>design unico</strong> e funzionale che ti farà risaltare tra la folla, ma anche un'esperienza di sviluppo <strong>divertente e senza stress</strong>. La differenza che facciamo non sta solo in ciò che facciamo, ma nel <strong>perché lo facciamo</strong>. Siamo qui per rendere il tuo viaggio online unico, travolgente e pieno di successo.</p>

					<HashLink to="#contattaci" className="btn">RACCONTACI DI TE</HashLink>
				</article>
			</section>

			<span className="sr-only offset-nav" id="contattaci" />
			<SectionContact />
		</>
	);
}